import request from '@/utils/request'

//活动视频列表
export function GetVideoList(params) {
  return request({
      url: `/api/kitchen-activity/video-list`,
      method: 'post',
      data: params
  })
}

//查看/点赞活动视频
export function AddLog(params) {
  return request({
      url: `/api/kitchen-activity/add-log`,
      method: 'post',
      data: params
  })
}