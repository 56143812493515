import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'normalize.css/normalize.css'
import 'vant/lib/index.css';
// px2rem 自适应
import 'lib-flexible'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import { Lazyload } from 'vant';
import global from '@/assets/js/global'
Vue.prototype.GLOBAL = global

Vue.use(Lazyload);

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
