import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV=='dev'? '':process.env.VUE_APP_BASE_API, // url = base url + request url process.env.VUE_APP_BASE_API
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 ,// request timeout
  headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  
})

// request interceptor
service.interceptors.request.use(
  config => {

    // do something before request is sent
    // if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // let token = location.hash.split('=')[1]
      let token  = '540ee3bc45d2b562af432f1b81c1944d'
      // config.headers['Authorization'] = 'Bearer'+' '+ token
      //请求头token
      // console.log(config)
      // console.log(process.env)
      if(process.env.NODE_ENV!="dev") {
        if(config.url.indexOf('/api')!=-1) {
          config.url = config.url.split('/api')[1]
        }
      }
    // }
    return config
  },
  error => {
    // do something with request error
    // console.log(error + "789") // for debug  
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data


    // if (res.code === 31808) {
    //   Message({
    //     message: '密码错误',
    //     type: 'error',
    //     duration: 2000
    //   })
    // }

    // if the custom code is not 20000, it is judged as an error.
    return res
  },
  error => {
    // console.log(error) // for debug

    return Promise.reject(error)
  }
)

export default service
