<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
//页面引入
import Vconsole from "vconsole";

export default {
  mounted() {
    console.log(process.env.NODE_ENV)
    if(process.env.NODE_ENV!='production') {
      //然后在created 或者mounted 生命周期中初始化一下
      let vConsole = new Vconsole();
    }
  },
};
</script>
<style lang="scss">
#app {
  width: 100vw;
  min-height: 100vh;
  background-color: #f7f7f7;
}
</style>
