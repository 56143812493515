<template>
  <div id="home">
    <div class="activity-msg">
      <div>活动时间：2021.4.1-2021.4.10</div>
      <!-- xxxxxxxxxxxx -->
      <div>活动规则：xxxxxxxxxxxx</div>
    </div>
    <van-tabs
      id="tabs"
      :class="{ changeWidth: isFixed }"
      :title-inactive-color="titleInactiveColor"
      @change="changeTab"
      :title-active-color="titleActiveColor"
      :color="titleColor"
      v-model="active"
      sticky
      @scroll="changeScroll"
    >
      <!-- 最新 -->
      <van-tab title="最新" name="newest">
        <van-pull-refresh
          v-if="active == 'newest'"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="vanCell" v-for="(item, index) in list" :key="index">
              <div class="cell-left">
                <!-- <video id="video-left" @click="pause" @ended="palyEnd" ref="videoCor" width="134" height="100%" :src="item.video_url"></video> -->
                <van-image
                  fit="cover"
                  height="100%"
                  @click="handlePlay(item)"
                  :src="item.img_url"
                ></van-image>
                <span
                  v-if="playIconHid"
                  @click="handlePlay(item)"
                  class="play-icon"
                ></span>
              </div>
              <div class="cell-right">
                <div class="van-multi-ellipsis--l2 title">{{ item.title }}</div>
                <div class="number">
                  <i class="icon-piao"></i> {{ item.thump_up }}
                </div>
                <div class="vote">
                  <van-button
                    v-if="item.is_thump_up == 0"
                    class="isfalse"
                    color="#FF8420"
                    round
                    type="info"
                    @click="immediatelyTo(item)"
                    >立即投票</van-button
                  >
                  <van-button
                    class="border-btn"
                    v-if="item.is_thump_up == 1"
                    color="#FF8420"
                    plain
                    round
                    type="info"
                    @click="already"
                    >已投票</van-button
                  >
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <!-- 最火 -->
      <van-tab title="最火" name="hottest" class="hottest-tab">
        <van-pull-refresh
          v-if="active == 'hottest'"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="vanCell" v-for="(item, index) in list" :key="index">
              <div
                :class="{
                  ranking: true,
                  one: index == 0,
                  two: index == 1,
                  three: index == 2,
                }"
              ></div>
              <div class="cell-left">
                <van-image
                  fit="cover"
                  height="100%"
                  @click="handlePlay(item)"
                  :src="item.img_url"
                ></van-image>
                <span
                  v-if="playIconHid"
                  @click="handlePlay(item)"
                  class="play-icon"
                ></span>
              </div>
              <div class="cell-right">
                <div class="van-multi-ellipsis--l2 title">{{ item.title }}</div>
                <div class="number">
                  <i class="icon-piao"></i> {{ item.thump_up }}
                </div>
                <div class="vote">
                  <van-button
                    v-if="item.is_thump_up == 0"
                    class="isfalse"
                    color="#FF8420"
                    round
                    type="info"
                    @click="immediatelyTo(item)"
                    >立即投票</van-button
                  >
                  <van-button
                    class="border-btn"
                    v-if="item.is_thump_up == 1"
                    color="#FF8420"
                    plain
                    round
                    type="info"
                    @click="already"
                    >已投票</van-button
                  >
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { GetVideoList, AddLog } from "@/api/master";
import qs from "qs";
export default {
  name: "Home",
  data() {
    return {
      active: "newest",
      list: [],
      loading: false,
      finished: false, //默认false
      refreshing: false,
      //播放图标显示隐藏
      playIconHid: true,
      page: 1,
      size: 10,
      total: 0,
      token: "",
      isFixed: false,
      titleActiveColor: "#02B2FF",
      titleColor: "#02B2FF",
      titleInactiveColor: "",
    };
  },
  mounted() {
    // this.token = location.hash.split("=")[1];
    this.token = this.$route.query.token
    // this.token  = '540ee3bc45d2b562af432f1b81c1944d'
  },
  methods: {
    async getList() {
      if (this.refreshing) {
        this.page = 1;
        this.list = [];
        this.refreshing = false;
      }
      let params = {
        token: this.token,
        order_by: this.active == "newest" ? 1 : 2, //视频分类 1：最新 2：最火
        page: this.page++,
        size: this.size,
      };
      let { data, code } = await GetVideoList(qs.stringify(params));
      if (code == 0) {
        data.list.forEach((item, index) => {
          this.list.push(item);
        });
        this.total = data.count;
      } else {
        this.list = [];
        this.total = 0;
        this.finished = true;
      }
      this.loading = false;
      if (this.list.length >= data.count) {
        // console.log(this.list.length+'jinlail'+data.count)
        // console.log('进来了')
        this.finished = true;
      }
      // console.log(data)
    },
    onLoad() {
      this.getList();
    },
    onRefresh() {
      // 清空列表数据
      this.page = 1;
      this.list = [];
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    // 切换最新 最火
    changeTab() {
      this.page = 1;
      // console.log(this.page)
      this.list = [];
      this.finished = false;
      // this.onLoad()
      //判断是否吸顶
      if (this.isFixed) {
      }
    },
    //播放图标
    handlePlay(val) {
      // this.playIconHid = false
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        // android
        window.AndroidWebView.showInfoFromJs(qs.stringify(val));
      }
      if (isiOS) {
        // IOS
        window.webkit.messageHandlers.showMessage.postMessage(val);
      }
    },
    // 立即投票
    async immediatelyTo(val) {
      let params = {
        token: this.token,
        video_id: val.id,
        type: "2",
      };
      let { code, data } = await AddLog(qs.stringify(params));
      console.log(code);
      if (code == 0) {
        // 修改当前点击的按钮状态
        // this.getList()
        val.is_thump_up = 1;
        val.thump_up = Number(val.thump_up) + 1;
        this.$toast("投票成功");
      } else {
        this.$toast("投票失败");
      }
    },
    already() {
      this.$toast("已投票");
    },
    changeScroll({ scrollTop, isFixed }) {
      this.isFixed = isFixed;
      if (this.isFixed) {
        this.titleActiveColor = "#fff";
        this.titleColor = "#fff";
        this.titleInactiveColor = "#fff";
      } else {
        this.titleActiveColor = "#02B2FF";
        this.titleColor = "#02B2FF";
        this.titleInactiveColor = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin bg-image($url) {
  background-image: url($url+"@1x.png");
  @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    background-image: url($url+"@2x.png");
  }
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    background-image: url($url+"@3x.png");
  }
}
#home {
  // position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffbe56;
  @include bg-image("../../assets/images/home");
  background-size: 100% 405px;
  background-repeat: no-repeat;
  padding-top: 176px;
  box-sizing: border-box;
  .activity-msg {
    width: 350px;
    // height: 63px;
    box-sizing: border-box;
    // background: #050505;
    border-radius: 14px;
    opacity: 0.82;
    padding: 15px;
    font-size: 14px;
    color: #fff;
    // opacity: 0.1;
    background: rgba(0, 0, 0, 0.1);
    line-height: 18px;
    // margin: 155px auto 29px;
    margin: 0 auto 10px;
    > div:first-child {
      padding-bottom: 9px;
    }
  }
  ::v-deep .van-tabs__wrap {
    border-radius: 22px;
    margin: 0 12px;
  }
  .van-list {
    margin: 0px 8px 8px;
    .vanCell {
      display: flex;
      width: 360px;
      height: 170px;
      padding: 0;
      border-radius: 8px;
      box-sizing: border-box;
      margin-top: 8px;
      background-color: #fff;
      overflow: hidden;
      .isfalse {
        font-weight: 500 !important;
        border: none;
        background: linear-gradient(
          134deg,
          #ff8420 0%,
          #ff3737 100%
        ) !important;
      }

      .cell-left {
        position: relative;
        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 36px;
          height: 36px;
          @include bg-image("../../assets/images/play");
          background-size: 36px 36px;
          background-repeat: no-repeat;
        }
      }
      .cell-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 16px;
        .title {
          font-size: 16px;
          font-weight: 400;
          color: #181830;
          line-height: 22px;
        }
        .number {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #181830;
        }
        .icon-piao {
          display: inline-block;
          width: 21px;
          height: 21px;
          @include bg-image("../../assets/images/icon");
          background-size: 21px 21px;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
        .vote {
          width: 80px;
          height: 32px;
          .van-button {
            width: 100%;
            height: 100%;
            font-size: 12px;
            font-weight: 400;
            // color: #FFFFFF;
            line-height: 16px;
            text-align: center;
            white-space: nowrap;
          }
        }
      }
      // ::v-deep .van-cell__value {
      //   display: flex;
      // }
    }
  }
  // 最火
  .hottest-tab {
    .vanCell {
      position: relative;
      .vote {
        .isfalse {
          font-weight: 500 !important;
          border: none;
          background: linear-gradient(
            134deg,
            #ff8420 0%,
            #ff3737 100%
          ) !important;
        }
        .istrue {
        }
      }
    }
    .ranking {
      position: absolute;
      left: 0;
      top: 0;
      width: 34px;
      height: 48px;
      background-size: 100% 48px;
      background-repeat: no-repeat;
      z-index: 10;
    }
    .one {
      @include bg-image("../../assets/images/one");
    }
    .two {
      @include bg-image("../../assets/images/two");
    }
    .three {
      @include bg-image("../../assets/images/three");
    }
  }

  .van-image {
    width: 134px;
    vertical-align: middle;
  }
  .changeWidth {
    ::v-deep .van-tabs__wrap {
      border-radius: 0;
      margin: 0;
      .van-tabs__nav {
        background: #fe6240;
      }
    }
  }
  ::v-deep .van-tabs__line {
    bottom: 22px;
  }
  .van-pull-refresh {
    // background-color: #FFBE56;
    // background: linear-gradient(to bootom, #ffd265, #ffbe56);
    padding-bottom: 20px;
    // min-height: 100px;
  }
  ::v-deep .van-image__img {
    vertical-align: middle;
  }
  ::v-deep .van-list__finished-text {
    color: #fff;
  }
  ::v-deep .van-tab--active {
    font-weight: 700;
  }
}
</style>
